<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>자원관리</p>
            </div>
            
            <router-view/>
        </div>
    </div>

</template>